<template>
    <b-sidebar id="add-new-invoice-remark-sidebar" :visible="isAddNewInvoiceRemarkSidebarActive" bg-variant="white" sidebar-class="sidebar-lg" shadow backdrop no-header right @hidden="resetForm" @change="(val) => $emit('update:is-add-new-invoice-remark-sidebar-active', val)">
        <template #default="{ hide }">
                  <!-- Header -->
                  <div
                    class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
                  >
                    <h5 class="mb-0">{{button_text}} 銷售備註</h5>
            
                    <feather-icon
                      class="ml-1 cursor-pointer"
                      icon="XIcon"
                      size="16"
                      @click="hide"
                    />
                  </div>
            
                  <!-- Body -->
                  <validation-observer 
                    #default="{ handleSubmit }"
                    ref="refFormObserver"
                  >
                  <b-overlay
                        :show='loading'
                        spinner-variant="primary"
                        spinner-type="grow"
                        spinner-small
                        rounded="sm"
                        >
                    <!-- Form -->
                    <b-form
                      class="p-2"
                      @submit.prevent="handleSubmit(onSubmit)"
                      @reset.prevent="resetForm"
                    >
                      <!-- 名稱-->
                      <validation-provider
                        #default="validationContext"
                        name="名稱"
                        rules="required"
                      >
                        <b-form-group label="名稱" label-for="name">
                          <b-form-input
                            id="name"
                            v-model="invoiceremarkData.name"
                            autofocus
                            :state="getValidationState(validationContext)"
                            trim
                            placeholder="名稱"
                           />
                            <b-form-invalid-feedback>
                              {{ validationContext.errors[0] }}
                            </b-form-invalid-feedback>
                         
                        </b-form-group>
                      </validation-provider>
                      <!-- 描述 -->
                      <validation-provider
                        #default="validationContext"
                        name="描述"
                        rules="required"
                      >
                        <b-form-group label="描述" label-for="remark">
                          <b-form-textarea
                            id="remark"
                            v-model="invoiceremarkData.remark"
                            placeholder="Display in project"
                            autofocus
                            :state="getValidationState(validationContext)"
                            trim
                            rows="15"
                          />
                          <b-form-invalid-feedback>
                            {{ validationContext.errors[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                      <!-- Form Actions -->
                      
                      <div class="d-flex mt-2">
                        <b-button
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          variant="primary"
                          class="mr-2"
                          type="submit"
                        >
                          {{button_text}}
                        </b-button>
                        <b-button
                          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                          type="button"
                          variant="outline-secondary"
                          @click="hide"
                        >
                          Cancel
                        </b-button>
                      </div>
                      
                    </b-form>
                    </b-overlay>
                  </validation-observer>
</template>
  </b-sidebar>
</template>

<script>
import {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BFormTextarea,
    BFormCheckbox,
    BOverlay,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { ref, watch } from "@vue/composition-api";
import { required, alphaNum, email } from "@validations";
import { max } from "@/libs/validations.js"
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import store from "@/store";
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
    components: {
        BSidebar,
        BForm,
        BFormGroup,
        BFormInput,
        BFormInvalidFeedback,
        BButton,
        BFormTextarea,
        BFormCheckbox,
        vSelect,
        BOverlay,

        // Form Validation
        ValidationProvider,
        ValidationObserver,
    },
    directives: {
        Ripple,
    },
    model: {
        prop: 'isAddNewInvoiceRemarkSidebarActive',
        event: 'update:is-add-new-invoice-remark-sidebar-active',
    },
    props: {
        isAddNewInvoiceRemarkSidebarActive: {
            type: Boolean,
            required: true,
        },
        id: {
            type: Number,
            required: true,
        },
        data: {
            type: Object,
            required: true,
        }
    },
    data() {
        return {
            required,
            alphaNum,
            email,
            max,
        }
    },
    setup(props, { emit }) {

        const loading = ref(false)

        const blankInvoiceRemarkData = {
            id: 0,
            name: '',
            remark: '',
        }
        const button_text = ref('新增')

        const invoiceremarkData = ref(JSON.parse(JSON.stringify(blankInvoiceRemarkData)))

        const resetinvoiceremarkData = () => {
            invoiceremarkData.value = JSON.parse(JSON.stringify(blankInvoiceRemarkData))
        }

        const toast = useToast()

        watch(() => [props.id, props.isAddNewInvoiceRemarkSidebarActive], () => {
            if (props.id > 0 && props.isAddNewInvoiceRemarkSidebarActive) {
              console.log('im here', props.data)
                invoiceremarkData.value = {...props.data}
                if (invoiceremarkData.value.id > 0) {
                    button_text.value = '修改'
                } else {
                    button_text.value = '新增'
                }
            } else {
                button_text.value = '新增'
            }
        })

        const onSubmit = () => {
            loading.value = true
            store.dispatch("setting-invoiceremark/addInvoiceRemark", invoiceremarkData.value)
                .then((response) => {
                    loading.value = false
                    emit("update:is-add-new-invoice-remark-sidebar-active", false);
                    emit('refetch-data')
                    toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Success',
                            text: response.data.message,
                            icon: 'CheckIcon',
                            variant: 'success'
                        }
                    })
                })
                .catch((error) => {
                    loading.value = false
                    toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Fail',
                            text: error.response.data.message,
                            icon: 'HeartIcon',
                            variant: 'danger'
                        }
                    })
                })
        };

        const {
            refFormObserver,
            getValidationState,
            resetForm,
        } = formValidation(resetinvoiceremarkData)

        return {
            invoiceremarkData,
            onSubmit,
            refFormObserver,
            getValidationState,
            resetForm,
            button_text,
            loading,
        };


    }

};
</script>

</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
#add-new-invoice-remark-sidebar {
    .vs__dropdown-menu {
        max-height: 200px !important;
    }
}
</style>